import React from 'react';
import homeScreenImage from './SuperStackSolitaire/superstacksolitaire_homescreen.png';
import pryamidScreenImage from './SuperStackSolitaire/superstacksolitaire_pyramid.png';
import klondikeImage from './SuperStackSolitaire/superstacksolitaire_klondike.png';
import mazegenerator1 from './MazeGenerator/1.png';
import mazegenerator2 from './MazeGenerator/2.png';
import mazegenerator3 from './MazeGenerator/3.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faItchIo } from '@fortawesome/free-brands-svg-icons'
import './imagebutton.css';
import './modal.css';
import './projects.css';
import mazegeneratorcover from './ProjectCovers/mazegenerator.png';
import superstacksolitairecover from './ProjectCovers/superstacksolitaire.png';
const projects = [
  {
    title: 'Super Stack Solitaire',
    description: 'Pyramid and Klondike solitaire featuring casual and arcade modes with an 8-bit aesthetic following a modified NES spec.',
    images: [{
      src: homeScreenImage,
      title: "Home Screen",
    }, {
      src: pryamidScreenImage,
      title: "Pyramid",
    }, {
      src: klondikeImage,
      title: "Klondike",
    }],
    link: "https://capsizegames.itch.io/super-stack-solitaire",
    releaseDate: 'June 05, 2022',
    platforms: ['PC'],
    tools: ['Unity3D', 'C#', 'FamiTracker', 'Aesprite'],
    status: 'In development',
    publisher: 'Capsize Games',
    coverImage: superstacksolitairecover
  },
  {
    title: 'Maze Generator',
    description: 'A maze generator plugin for Unreal Engine which uses a growing tree algorithm to create a maze.',
    images: [{
      src: mazegenerator1,
      title: "Maze with rooms",
    }, {
      src: mazegenerator2,
      title: "Maze without rooms",
    }, {
      src: mazegenerator3,
      title: "Large maze",
    }],
    link: "",
    releaseDate: 'TBA',
    platforms: ['PC'],
    tools: ['UnrealEngine', 'C++'],
    status: 'In development',
    publisher: 'Capsize Games',
    coverImage: mazegeneratorcover
  },
  // {
  //   title: 'Wild West Poker',
  //   description: '',
  //   images: [],
  //   link: "https://capsizegames.itch.io/wild-west-poker",
  //   releaseDate: '',
  //   platforms: ['PC'],
  //   tools: ['Unity3D', 'C#', 'FamiTracker', 'Aesprite'],
  //   status: 'In development'
  // },
  // {
  //   title: 'Unity BinaryFileSaver',
  //   description: '',
  //   images: [],
  //   link: "https://capsizegames.itch.io/binaryfilesaver",
  //   releaseDate: '',
  //   platforms: ['PC'],
  //   tools: ['Unity3D', 'C#'],
  //   status: 'Released'
  // },
  // {
  //   title: 'Unity GameObjectPool Manager',
  //   description: '',
  //   images: [],
  //   link: "https://capsizegames.itch.io/unity-gameobjectpool",
  //   releaseDate: '',
  //   platforms: ['PC'],
  //   tools: ['Unity3D', 'C#'],
  //   status: 'Released'
  // }
];

class Projects extends React.Component {
  constructor() {
    super();
    this.state = {
      coverImage: null
    };
  }

  closeModal = () => {
    this.setState({ coverImage: null });
  }

  renderCoverImage = () => (this.state.coverImage ? (
    <div className="modal coverimage" onClick={this.closeModal}>
      <span onClick={this.closeModal} className="close">&times;</span>
      <img alt={"product cover"} className="modal-content" src={this.state.coverImage} />
      <div className="caption"></div>
    </div>
  ) : null)

  showImage = (image) => {
    this.setState({coverImage: image});
  }

  renderProject = (data, projectindex) => (
    <div className="project" key={`project-key-${projectindex}`}>
      <h3>
        {data.title}
      </h3>
      <p className="description">{data.description}</p>
      <div className="meta-data">
        {data.link ? (
          <div className="marketplaces">
            <small>marketplaces</small>
            {data.link ? (<a href={data.link}><FontAwesomeIcon icon={faItchIo} /></a>) : null}
          </div>
        ) : null }
        <table className="details">
          <tr>
            <td><strong>Status</strong></td>
            <td>{data.status}</td>
          </tr>
          {data.releaseDate ? (
            <tr>
              <td><strong>Release date</strong></td>
              <td>TBA</td>
            </tr>
          ) : null}
          <tr>
            <td><strong>Platforms</strong></td>
            <td>{data.platforms.join(', ')}</td>
          </tr>
          <tr>
            <td><strong>Tools</strong></td>
            <td>{data.tools.join(', ')}</td>
          </tr>
        </table>
      </div>
      <div className="images">
        {data.images.map((image, index) => (
        <div key={`image-key-${index}`} className="image-container" onClick={() => this.showImage(image.src)}>
          <img alt={"project"} style={{ width: 256, height: 144 }} src={image.src} />
          <div className="image-text-container">
            <div className="image-text">{image.title}</div>
          </div>
        </div>))}
      </div>
      {this.renderCoverImage()}
    </div>
  )

  selectProject = (data, projectIndex) => {
    this.setState({ project: data, projectIndex: projectIndex });
  }

  renderProjectLink = (data, projectindex) => (
    <div className="project" key={`project-key-${projectindex}`}>
      <img alt={"project cover"} onClick={() => this.selectProject(data, projectindex)} className="coverimage" src={data.coverImage} />
    </div>
  )

  render() {
    return (
      <div className="section-content projects">
        {this.state.project ? this.renderProject(this.state.project, this.state.projectIndex) : projects.map(this.renderProjectLink)}
      </div>
    )
  }
}

export default Projects;