import React from 'react';
import ReactDOM from "react-dom/client";
import {
  HashRouter,
  Routes,
  Route,
  Outlet,
  useLocation,
  useNavigate
} from "react-router-dom";
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faDiscord, faYoutube, faInstagram, faItchIo, faArtstation } from '@fortawesome/free-brands-svg-icons'
import { faHamburger } from '@fortawesome/free-solid-svg-icons';
import Projects from './Projects';
import Contact from './Contact';
import logo from './capsizelogo.png';
import './reset.css';
import './App.css';

const navigation = ["projects", "contact"];

ReactDOM.createRoot(
  document.getElementById("root")
);

class Footer extends React.Component
{
  render() {
    return (
      <p className="footer">
        &copy; 2022 Capsize LLC all rights reserved
      </p>
    );
  }
}

const Home = () => {
  const navigate = useNavigate();

  const renderNavigation = () => (
    <div className="navigation">
      {navigation.map((nav, index) => (<span className="navbutton" onClick={() => navigate(`/${nav}`, {replace: true})} key={`navkey-${index}`}>{nav}</span>))}
    </div>
  )

  return (
    <header className="App-header">
      <img alt={"logo"} className="home-logo" src={logo} />
      {renderNavigation()}
      <Footer />
    </header>
  );
}

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showHiddenLinks, toggleNav] = useState(false);

  // const match = useMatch("write-the-url-you-want-to-match-here");

  const sociallinks = [
    {
      url: 'https://capsizegames.itch.io/',
      icon: faItchIo
    },
    {
      url: 'https://www.artstation.com/capsize',
      icon: faArtstation
    },
    {
      url: 'https://discord.gg/PUVDDCJ7gz',
      icon: faDiscord
    },
    {
      url: 'https://twitter.com/capsizegames',
      icon: faTwitter
    },
    {
      url: 'https://www.youtube.com/channel/UCGp3N3DlNYk6BPR9-DXkSzA',
      icon: faYoutube
    },
    {
      url: 'https://www.instagram.com/capsizegames/',
      icon: faInstagram
    }
  ];

  const renderSocialLinks = () => sociallinks.map(link => (<li><a target="_blank" href={link.url} rel={"noreferrer"}><FontAwesomeIcon icon={link.icon} /></a></li>))

  const renderNavigation = () => (
    <div className="top-navigation">
      <div className="navigation-container">
        <div className="brand">
          <span className="brandname" onClick={() => navigate('/', {replace: true})}><img alt={"logo"} src={logo} /></span>
          <div className="navbar">
            {navigation.map((nav, index) => (<span className={`navbutton${location.pathname === `/${nav}` ? ' selected' : ''}`} onClick={() => navigate(`/${nav}`, {replace: true})} key={`navkey-${index}`}>{nav}</span>))}
          </div>
        </div>
        <div className="socialLinks">
          <ul className="expandedNav">
            {renderSocialLinks()}
          </ul>
          <div className="socialLinks-collapsebutton">
            <button className="hamburgerButton" onClick={() => {
              toggleNav(!showHiddenLinks)
              console.log("test " + showHiddenLinks)
            }}>
              <FontAwesomeIcon icon={faHamburger} />
            </button>
            {showHiddenLinks ? (
              <div className="hiddenNav">
                <ul className="nav">
                  {navigation.map((nav, index) => (<span className="navbutton" onClick={() => {
                    toggleNav();
                    navigate(`/${nav}`, {replace: true});
                  }} key={`navkey-${index}`}>{nav}</span>))}
                </ul>
                <ul className="social">
                {renderSocialLinks()}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <div className="App">
      <div className="section">
        {location.pathname !== '/' ? renderNavigation() : null}
        <Outlet />
        <Footer />
      </div>
    </div>
  );
}

const AppMain = () => {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/contact" element={<Contact />} />
        </Route>
      </Routes>
    </HashRouter>
  );
}

export default AppMain;
